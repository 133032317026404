@import "./Model.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $mainTextColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  scroll-behavior: smooth;
}
body {
  line-height: 1;
  font-family: serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}

h1 {
  font-size: 24px;
  border-bottom: 3px solid $mainColor;
  padding-bottom: 4px;
  margin-bottom: 16px;
}

h2 {
  font-size: 24px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

article {
  max-width: $mainContainer;
  margin: 0 auto;
}

.block {
  overflow: hidden;
  margin-top: 36px;
}

.logo-font {
  font-family: serif;
}

.unlink {
  color: $mainTextColor !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.icon-size {
  font-size: 18px !important;
  padding-right: 4px;
  vertical-align: middle;
}

.color-white {
  color: white !important;
}

.bold {
  font-weight: bold;
}

.button-strong {
  width: 100%;
  font-weight: bold !important;
}

.border-bottom {
  border-bottom: 2px solid $mainColor;
}

.read-more {
  padding: 0px 16px;
  text-align: right;
  overflow: hidden;
}

.center {
  text-align: center;
}

.headline {
  line-height: 32px;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc;
}

.description {
  line-height: 20px;
  font-size: 13px;
}

.strong {
  font-weight: bold;
}

.big-string {
  font-size: 24px;
}

.padding-right-4 {
  padding-right: 4px;
}

.make-space {
  padding: 12px 4px;
}

.enough-line-height {
  line-height: 20px;
}

.one-column {
  max-width: 1020px;
  margin: 0 auto;
  margin-top: 28px;
}

.one-column h1 {
  text-align: center;
  border: none !important;
}

.one-column-form {
  width: 65%;
  margin: 0 auto;
}

.loading-wrapper {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.no-border {
  border: none !important;
}

.color-white {
  color: #fff;
}

.font-logo {
  font-family: "Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond,
    "Times New Roman", serif;
}

.emphasize-link {
  text-decoration: underline;
  font-weight: bold;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.only-mobile {
  display: none;
}

@media screen and (max-width: 1020px) {
  article {
    width: 98%;
    margin: 0 auto;
  }

  h1,
  h2 {
    font-size: 20px;
  }

  h2 {
    margin-bottom: 8px;
  }

  .one-column-form {
    width: 90%;
  }

  .only-mobile {
    display: block;
  }
}
